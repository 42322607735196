<template>
  <div>
    <!-- Tabs -->
    <v-tabs
      color="secondary"
      :value="currentTab"
      @change="tabChange"
    >
      <v-tab
        v-for="(tab, i) in tabList"
        :key="i"
        :href="tab.href"
        :disabled="isDisabled(tab)"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <BaseConfirmModal
      :value="showUnsaved"
      :title="unsavedContentTitle"
      :text="unsavedContentText"
      declineText="Cancel"
      @clicked="unsavedModalConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import baseTabMixin from '@/components/mixins/workspace/baseTabMixin';

export default {
  name: 'TenantsTabs',
  data: () => ({
    tabList: [
      {
        title: 'General Info',
        href: '#tab-general',
        link: 'tab-general',
        component: 'TenantEdit',
        atLeastTm: false,
      },
      {
        title: 'Applications',
        href: '#tab-apps',
        link: 'tab-apps',
        component: 'TenantApplicationEdit',
        atLeastTm: true,
      },
      {
        title: 'Tenant Biometrics',
        href: '#tab-biometrics',
        link: 'tab-biometrics',
        component: 'TenantBiometricEdit',
        atLeastTm: false,
      },
      {
        title: 'Alert Templates',
        href: '#tab-templates',
        link: 'tab-templates',
        component: 'TenantTemplateEdit',
        atLeastTm: true,
      },
    ],
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'roleAtLeastTm', 'getUnsaved']),
    ...mapGetters('tabs', {
      currentTab: 'getSelected',
    }),
  },
  methods: {
    ...mapActions('tabs', {
      setCurrentTab: 'setSelected',
    }),
    isDisabled(item) {
      if (item.component === 'TenantEdit') {
        return false;
      }
      if (item.atLeastTm && !this.roleAtLeastTm) {
        return true;
      }
      return !this.getStickyTenant;
    },
  },
  mixins: [baseTabMixin],
};
</script>
